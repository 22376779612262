<template>
  <div>
    <user-form
      ref="userForm"
      :is-loading="isLoading"
      :is-user-loading="isLoading"
      :preview="preview"
      :user="user"
      :form-title="formTitle"
      submit-button-text="Update"
      @submit="submit"
    >
      <template v-slot:card-subtitle>
        <div class="mt-6"></div>
        <div v-if="user" class="caption">
          Status:
          <span :class="`${user && user.active ? 'success' : 'red'}--text`">{{
            activeStatus
          }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                elevation="2"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                @click="handleSetActive(!user.active)"
              >
                <v-icon>
                  mdi-lock{{ user.active ? "" : "-open-variant" }}-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Set user {{ user.active ? "inactive" : "active" }}</span>
          </v-tooltip>
        </div>
        <v-row>
          <v-col>
            <v-tooltip bottom v-if="user && user.token">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="user.token"
                  label="Token"
                  :disabled="isLoading"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :loading="isLoading"
                  :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
                  append-outer-icon="mdi-content-copy"
                  @focus="copyToken"
                  @click:append-outer="copyToken"
                />
              </template>
              <span>{{ user.token }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4">
            <v-btn
              color="warning"
              block
              :loading="isTokenRegenerating"
              :disabled="user && !user.active"
              @click="openRegenerateTokenConfirmationModal"
            >
              Regenerate Token
            </v-btn>
          </v-col>
        </v-row>
        <input id="clipboard-holder" type="text" hidden />
        <v-divider class="mt-6 mb-6" />
        <v-btn color="primary" :disabled="!preview" @click="preview = false">
          <v-icon small class="mr-3">mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          color="primary"
          class="ml-3"
          outlined
          :disabled="preview"
          @click="cancelEditing"
        >
          Cancel editing
        </v-btn>
      </template>
    </user-form>
  </div>
</template>

<script>
import UserForm from "@/views/Panel/views/Users/components/UserForm";
import { mapActions, mapState } from "vuex";
import { mapUserObject } from "@/views/Panel/views/Users/helpers";
import { copyTextToClipboard } from "@/helpers/helpers";
export default {
  name: "User",
  components: { UserForm },
  data() {
    return {
      isLoading: false,
      preview: true,
      user: null,
      isTokenRegenerating: false
    };
  },
  computed: {
    ...mapState({
      markets: state => state.api.misc.markets
    }),
    formTitle() {
      const baseText = this.preview ? "Preview user" : "Edit user";
      return `${baseText}${this.user ? ": " + this.user.fullname : ""}`;
    },
    activeStatus() {
      let status = "no data";
      if (this.user && Object.keys(this.user).includes("active")) {
        status = this.user.active ? "active" : "inactive";
      }
      return status;
    }
  },
  mounted() {
    this.handleGetUser();
  },
  watch: {
    "$route.params.id"() {
      this.preview = true;
      this.handleGetUser();
    }
  },
  methods: {
    ...mapActions({
      getUser: "api/management/user/getUser",
      updateUser: "api/management/user/updateUser",
      regenerateToken: "api/management/user/regenerateToken"
    }),
    cancelEditing() {
      this.$refs.userForm.setUserForm();
      this.preview = true;
    },
    openRegenerateTokenConfirmationModal() {
      this.$modals.confirmation({
        action: this.handleRegenerateToken
      });
    },
    handleRegenerateToken() {
      this.isTokenRegenerating = true;

      this.regenerateToken(this.user._id)
        .then(res => {
          this.user.token = res.data.token;
          this.$notifier.notify({
            message: "Token regenerated!",
            color: "success"
          });
        })
        .catch(e => {
          console.log(e);
          this.$modals.validation({
            content: `Cannot regenerate token. ${e}`,
            title: "Error"
          });
        })
        .finally(() => {
          this.isTokenRegenerating = false;
        });
    },
    handleGetUser() {
      this.isLoading = true;

      this.getUser(this.$route.params.id)
        .then(response => {
          this.user = response.data;
        })
        .catch(error => {
          this.$notifier.notify({
            message: "Cannot get user",
            color: "error"
          });
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    copyToken() {
      copyTextToClipboard(this.user.token);
      this.$notifier.notify({
        message: this.$t("COMMON.NOTIFICATIONS.TEXT_COPIED")
      });
    },
    handleSetActive(active) {
      this.$modals.confirmation({
        action: this.submit.bind(
          this,
          {
            ...this.user,
            active
          },
          true
        )
      });
    },
    submit(data, updateActiveOnly = false) {
      this.isLoading = true;
      const { id } = this.$route.params;

      this.updateUser({ id, data: mapUserObject(data, this.markets) })
        .then(() => {
          this.$root.$emit("refresh-user-list");

          if (!updateActiveOnly) {
            this.$refs.userForm.handleCancel();
            this.$notifier.notify({
              message: "User updated successfully",
              color: "success"
            });
          } else {
            this.handleUpdateActiveOnlyResponse();
          }
        })
        .catch(error => {
          this.$notifier.notify({
            message: "Cannot update user",
            color: "error"
          });
          if (
            Object.keys(error.response.data.error.fieldErrors).includes("email")
          ) {
            this.$modals.validation({
              content: error.response.data.error.fieldErrors.email.join("/n"),
              title: "Email must be unique"
            });
          }
          console.log({ error });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleUpdateActiveOnlyResponse() {
      this.handleGetUser();

      this.$notifier.notify({
        message: "User active status updated successfully",
        color: "success"
      });
    }
  }
};
</script>
